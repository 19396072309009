import Axios, { AxiosInstance } from 'axios';
import { Settings } from '@/settings';

export interface IAxiosAdminSubscriptionKeyInterceptorOptions {
  axios?: AxiosInstance;
}

export function registerAdminSubscriptionKeyInterceptor (options?: IAxiosAdminSubscriptionKeyInterceptorOptions): void {
  const axios = options?.axios ?? Axios;

  axios.interceptors.request.use(
    (config) => {
      const urlStr = config.url ?? config.baseURL;
      if (urlStr) {
        const url = new URL(urlStr);
        const adminUrl = new URL(Settings.adminApiBaseUrl);
        if (url.hostname.toLowerCase() === adminUrl.hostname.toLowerCase()) {
          config.headers['Ocp-Apim-Subscription-Key'] = Settings.adminApiSubscriptionKey;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
