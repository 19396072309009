import Axios, { AxiosInstance } from 'axios';
import { store as loginStore } from '@/store/login';
import { Settings } from '@/settings';

export interface IAxiosAdminMSALInterceptorOptions {
  axios?: AxiosInstance;
}

export function registerAdminMsalInterceptor (options?: IAxiosAdminMSALInterceptorOptions): void {
  const axios = options?.axios ?? Axios;

  axios.interceptors.request.use(
    async (config) => {
      const urlstr = config.url ?? config.baseURL;
      if (urlstr) {
        const url = new URL(urlstr);
        const adminUrl = new URL(Settings.adminApiBaseUrl);
        if (url.hostname.toLowerCase() === adminUrl.hostname.toLowerCase()) {
          const accessToken = await loginStore.getAccessToken();
          if (accessToken) {
            // This is where the Bearer token is added to the admin requests
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
