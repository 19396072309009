FileList.prototype.toArray = function (): File[] {
  const files: File[] = [];
  for (let i = 0; i < this.length; i++) {
    const f = this.item(i);
    if (f != null) {
      files.push(f);
    }
  }
  return files;
};
