import { IIndexable } from '@/utils/indexable';
import { DeepReadonly } from '@vue/reactivity';
import { AxiosResponse, CancelTokenSource } from 'axios';

export enum UploadState {
  loading = 'loading',
  success = 'success',
  failed = 'failed'
}

export interface IUploadInformation {
  id: number;
  fileName: string;
  xhr: AxiosResponse | null;
  cancelToken: CancelTokenSource;
  uploadState: UploadState;
  statusDisplay?: string;
  cancelled: boolean;
}

export interface IUploadState {
  uploads: IIndexable<IUploadInformation[]>;
  isOpen: boolean;
  notify: boolean;
  routedNamespace: string;
  uploadId: number;
  subscriptionKey: string | null;
}

export type TSetStatusDisplay = (upload: IUploadInformation, statusDisplay?: string) => void;
export type TUploadCompleteCallback = (i: IUploadInformation[], setStatusDisplay: TSetStatusDisplay) => Promise<void>;
export type TUpload = (namespace: string, url: string, files: File[], uploadComplete: TUploadCompleteCallback) => Promise<boolean>;
export type TAbortUpload = (namespace: string, upload: IUploadInformation) => void;
export type TSetUploads = (uploads: IIndexable<IUploadInformation[]>) => void;
export type TSetIsOpen = (isOpen: boolean) => void;
export type TSetNotify = (notify: boolean) => void;
export type TSetRoutedNamespace = (routedNamespace: string) => void;
export type TSetSubscriptionKey = (subscriptionKey: string | null) => void;
export type TCleanupSuccess = (namespace: string) => void;
export type TReset = () => void;

export interface IUploadStore {
  state: DeepReadonly<IUploadState>;
  upload: TUpload;
  abortUpload: TAbortUpload;
  setUploads: TSetUploads;
  setIsOpen: TSetIsOpen;
  setNotify: TSetNotify;
  setRoutedNamespace: TSetRoutedNamespace;
  setSubscriptionKey: TSetSubscriptionKey;
  cleanupSuccess: TCleanupSuccess;
  reset: TReset;
  readonly noUploads: boolean;
}
