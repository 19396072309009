import { nullable } from '@/utils/nullable';

export interface ISettings {
  appVersion: string;
  adminApiBaseUrl: string;
  adminApiSubscriptionKey: string;
  clientApiBaseUrl: string;
  clientApiSubscriptionKey: string;
  msalClientId: string;
  msalTenantId: string;
  msalAppIdentifier: string;
  environmentMode: string;
  defaultContractQuota: number | null;
  defaultMonthlyQuota: number | null;
  defaultDailyQuota: number | null;
}

export const Settings: ISettings = {
  appVersion: process.env.VUE_APP_VERSION,
  adminApiBaseUrl: process.env.VUE_APP_ADMIN_API_BASEURL,
  adminApiSubscriptionKey: process.env.VUE_APP_ADMIN_API_SUBSCRIPTION_KEY,
  clientApiBaseUrl: process.env.VUE_APP_API_BASEURL,
  clientApiSubscriptionKey: process.env.VUE_APP_API_SUBSCRIPTION_KEY,
  msalClientId: process.env.VUE_APP_MSAL_CLIENT_ID,
  msalTenantId: process.env.VUE_APP_MSAL_TENANT_ID,
  msalAppIdentifier: process.env.VUE_APP_MSAL_APP_IDENTIFIER,
  environmentMode: process.env.NODE_ENV,
  defaultContractQuota: nullable(process.env.VUE_APP_DEFAULT_CONTRACT_QUOTA, (val) => {
    const result = Number.parseInt(val);
    return isNaN(result) ? null : result;
  }) ?? null,
  defaultMonthlyQuota: nullable(process.env.VUE_APP_DEFAULT_MONTHLY_QUOTA, (val) => {
    const result = Number.parseInt(val);
    return isNaN(result) ? null : result;
  }) ?? null,
  defaultDailyQuota: nullable(process.env.VUE_APP_DEFAULT_DAILY_QUOTA, (val) => {
    const result = Number.parseInt(val);
    return isNaN(result) ? null : result;
  }) ?? null
};
