import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { SubscriptionRoutes } from '@/router/routes/subscriptions';
import { ImageCategoryRoutes } from '@/router/routes/imageCategories';
import { passThrough, transformRouteParams } from './paramTransformer';
import { ApiTestRoutes } from '@/router/routes/apiTest';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/subscription',
    component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/Subscriptions.vue'),
    children: [
      {
        path: '',
        name: SubscriptionRoutes.base,
        redirect: { name: SubscriptionRoutes.list }
      },
      {
        path: 'list',
        name: SubscriptionRoutes.list,
        component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/SubscriptionListPage.vue')
      },
      {
        path: 'view/:subscriptionId',
        name: SubscriptionRoutes.view,
        component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/SubscriptionViewPage.vue'),
        props: (route) => {
          return {
            ...transformRouteParams({
              subscriptionId: passThrough
            })(route)
          };
        }
      },
      {
        path: 'generate',
        name: SubscriptionRoutes.generate,
        component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/SubscriptionGeneratePage.vue')
      },
      {
        path: 'transactions/:subscriptionId',
        name: SubscriptionRoutes.transactions,
        component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/subscriptions/SubscriptionTransactionsPage.vue'),
        props: (route) => {
          return {
            ...transformRouteParams({
              subscriptionId: passThrough
            })(route)
          };
        }
      }
    ]
  },
  {
    path: '/image-category',
    component: () => import(/* webpackChunkName: "imageCategories" */ '@/views/ImageCategories.vue'),
    children: [
      {
        path: '',
        name: ImageCategoryRoutes.base,
        redirect: { name: ImageCategoryRoutes.list }
      },
      {
        path: 'list',
        name: ImageCategoryRoutes.list,
        component: () => import(/* webpackChunkName: "imageCategories" */ '@/views/imageCategories/ImageCategoryListPage.vue')
      }
    ]
  },
  {
    path: '/api-test',
    component: () => import(/* webpackChunkName: "apiTest" */ '@/views/ApiTest.vue'),
    children: [
      {
        path: '',
        name: ApiTestRoutes.index,
        redirect: { name: ApiTestRoutes.imageAnalysis }
      },
      {
        path: 'image-analysis',
        name: ApiTestRoutes.imageAnalysis,
        component: () => import(/* webpackChunkName: "apiTest" */ '@/views/apiTest/ImageAnalysis.vue')
      }
    ]
  },
  {
    path: '/',
    redirect: { name: SubscriptionRoutes.base }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
