import { IUploadState } from '@/store/contracts/apiTest/upload';
import { ValidationModel } from '@/validation';
import { stringRequiredRule } from '@/validation/common/rules';

export enum UploadStateFieldName {
  SubscriptionKey = 'subscriptionKey'
}

class UploadStateValidationModel extends ValidationModel<IUploadState> {
  constructor () {
    super();

    this.setField(UploadStateFieldName.SubscriptionKey, {
      getter: (model) => model?.subscriptionKey ?? null,
      rules: [
        stringRequiredRule('Subscription Key is required.')
      ]
    });
  }

  public get modelName (): string {
    return 'UploadState';
  }
}

export default new UploadStateValidationModel();
