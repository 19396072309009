
import './styles/quasar.scss';
import iconSet from 'quasar/icon-set/mdi-v4.js';
import '@quasar/extras/mdi-v4/mdi-v4.css';
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Mutation from 'quasar/src/directives/Mutation.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    screen: {
      bodyClasses: true
    }
  },
  plugins: {
    Notify,
    Loading,
    Dialog,
    Mutation
  },
  iconSet: iconSet
};
