import { isNullOrWhitespace } from './stringUtils';

String.prototype.capitalize = function () {
  if (this.length <= 0) {
    return '';
  }
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.replaceAllInstances = function (searchStr: string | RegExp, replaceWith: string): string {
  const parts = this.split(searchStr);
  return parts.join(replaceWith);
};

String.prototype.localeIncludes = function (searchStr: string, options?: Intl.CollatorOptions): boolean {
  if (isNullOrWhitespace(searchStr) || isNullOrWhitespace(this?.toString())) {
    return false;
  }
  const thisStr = this;
  const opts = options ?? {};
  // More performant than using string.localeCompare, since we are potentially doing multiple comparisons.
  const collator = new Intl.Collator('en', opts);
  return thisStr.split('').filter((_c, idx) =>
    collator.compare(thisStr.slice(idx, idx + searchStr.length), searchStr) === 0).length > 0;
};
