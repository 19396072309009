export function nullable<T, V> (
  value: T | null | undefined,
  onNotNull: (val: T) => V)
  : V | null | undefined {
  if (value === null || value === undefined) {
    return value as (V | null | undefined);
  }
  return onNotNull(value);
}

export function possiblyUndefined<T, V> (
  value: T | undefined,
  onNotUndefined: (val: T) => V)
  : V | undefined {
  if (value === undefined) {
    return value as (V | undefined);
  }
  return onNotUndefined(value);
}

export function possiblyNull<T, V> (
  value: T | null,
  onNotNull: (val: T) => V)
  : V | null {
  if (value === null) {
    return value as (V | null);
  }
  return onNotNull(value);
}
