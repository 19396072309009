import { provide, inject, reactive, App, readonly } from 'vue';
import { IUploadState, IUploadStore, IUploadInformation, TUpload, TAbortUpload, TSetUploads, TCleanupSuccess, UploadState, TSetIsOpen, TSetRoutedNamespace, TReset, TSetStatusDisplay, TUploadCompleteCallback, TSetSubscriptionKey } from '@/store/contracts/apiTest/upload';
import { impl } from '@/utils/impl';
import { isNullOrWhitespace } from '@/utils/stringUtils';
import Axios, { AxiosResponse } from 'axios';
import { store as validationStore } from '@/store/validation';
import validationModel from '@/validation/uploadStateValidationModel';

const UploadStoreKey = Symbol('UploadStore');
const Batch = false; // Just hard coding this hear so can include logic to batch if that ever speeds up.

const createState = () => reactive(impl<IUploadState>({
  uploads: {},
  isOpen: false,
  notify: false,
  routedNamespace: 'Transcribe',
  uploadId: 0,
  subscriptionKey: null
}));

function setUploads (state: IUploadState): TSetUploads {
  return (uploads) => {
    state.uploads = uploads;
  };
}

function setIsOpen (state: IUploadState): TSetIsOpen {
  return (isOpen) => {
    state.isOpen = isOpen;
    if (isOpen) {
      setNotify(state)(false);
    }
  };
}

function setNotify (state: IUploadState): TSetIsOpen {
  return (notify) => {
    state.notify = notify;
  };
}

function setRoutedNamespace (state: IUploadState): TSetRoutedNamespace {
  return (routedNamespace) => {
    state.routedNamespace = routedNamespace;
  };
}

function setSubscriptionKey (state: IUploadState): TSetSubscriptionKey {
  return (subscriptionKey) => {
    state.subscriptionKey = subscriptionKey;
  };
}

function upload (state: IUploadState): TUpload {
  return async (namespace: string, url: string, files: File[], uploadComplete: TUploadCompleteCallback) => {
    const isValid = await validationStore.doValidation({
      model: validationModel,
      value: state
    });
    if (isValid) {
      if (Batch) {
        sendRequest(state, namespace, url, files, uploadComplete);
      } else {
        files.forEach(f => sendRequest(state, namespace, url, [f], uploadComplete));
      }
    }

    return isValid;
  };
}

function sendRequest (state: IUploadState, namespace: string, url: string, files: File[], uploadComplete: TUploadCompleteCallback) {
  if (isNullOrWhitespace(url)) {
    console.error('uploadStore: invalid or no URL specified!!');
    return;
  }

  const cancelTokenSource = Axios.CancelToken.source();
  const cancelToken = cancelTokenSource.token;

  const uploads = [] as IUploadInformation[];
  files.forEach(f => uploads.push({
    id: state.uploadId++,
    fileName: f.name,
    xhr: null,
    cancelToken: cancelTokenSource,
    uploadState: UploadState.loading,
    cancelled: false
  }));

  const setRequestState = (requestState: UploadState) => {
    uploads.forEach(u => {
      const stateUpload = state.uploads[namespace].find(su => su.id === u.id);
      if (!stateUpload) {
        return;
      }
      stateUpload.uploadState = requestState;
    });

    if (!state.isOpen) {
      setNotify(state)(true);
    }
  };

  const setStatusDisplay = (): TSetStatusDisplay => {
    return (upload: IUploadInformation, statusDisplay?: string) => {
      const stateUpload = state.uploads[namespace].find(u => u.id === upload.id);
      if (!stateUpload) {
        return;
      }
      stateUpload.statusDisplay = statusDisplay;
    };
  };

  const setAxiosResponse = (response: AxiosResponse) => {
    uploads.forEach(u => {
      const stateUpload = state.uploads[namespace].find(su => su.id === u.id);
      if (!stateUpload) {
        return;
      }
      stateUpload.xhr = response;
    });
  };

  if (!state.uploads[namespace]) {
    setUploads(state)({ ...state.uploads, [namespace]: [] });
  }

  state.uploads[namespace].push(...uploads);

  const blob = new Blob(files);

  const contentType = isNullOrWhitespace(blob.type) ? files[0].type : blob.type;

  Axios.post(url, blob, {
    withCredentials: true,
    cancelToken: cancelToken,
    headers: {
      'Content-Type': contentType,
      'X-PL-Subscription-Key': state.subscriptionKey
    }
  }).then(async (r) => {
    setAxiosResponse(r);
    await uploadComplete(uploads, setStatusDisplay());

    // If our first upload is cancelled, they all are. Just get out.
    const cancelled = uploads[0].cancelled;
    if (cancelled) {
      return;
    }

    if (state.routedNamespace === namespace) {
      const myNamespace = state.uploads[namespace];
      uploads.forEach(u => myNamespace.splice(state.uploads[namespace].findIndex(su => su.id === u.id), 1));
      setNotify(state)(false);
      return;
    }
    setRequestState(UploadState.success);
  }).catch((e) => {
    setAxiosResponse(e.response);
    setRequestState(UploadState.failed);
  });

  if (!state.isOpen) {
    setNotify(state)(true);
  }
}

function abortUpload (state: IUploadState): TAbortUpload {
  return (namespace: string, upload: IUploadInformation) => {
    const myNamespace = state.uploads[namespace];

    const index = myNamespace.findIndex(u => u.id === upload.id);
    const namespaceUpload = myNamespace[index];
    namespaceUpload.cancelToken.cancel();
    namespaceUpload.cancelled = true;
    myNamespace.splice(index, 1);
  };
}

function cleanupSuccess (state: IUploadState): TCleanupSuccess {
  return (namespace) => {
    const myNamespace = state.uploads[namespace];
    if (!myNamespace) {
      return;
    }
    state.uploads[namespace] = myNamespace.filter(u => u.uploadState !== UploadState.success);
  };
}

function reset (state: IUploadState): TReset {
  return () => {
    setUploads(state)({});
    setIsOpen(state)(false);
    setNotify(state)(false);
    setRoutedNamespace(state)('Transcribe');
  };
}

function createForState (state: IUploadState): IUploadStore {
  return {
    state: readonly(state),
    upload: upload(state),
    abortUpload: abortUpload(state),
    setUploads: setUploads(state),
    setIsOpen: setIsOpen(state),
    setNotify: setNotify(state),
    setRoutedNamespace: setRoutedNamespace(state),
    setSubscriptionKey: setSubscriptionKey(state),
    cleanupSuccess: cleanupSuccess(state),
    reset: reset(state),
    get noUploads (): boolean {
      if (!state || !state.uploads) {
        return true;
      }
      return Object.keys(state.uploads).none(k => state.uploads[k].length > 0);
    }
  };
}

export function provideStore (app?: App<Element>): IUploadStore {
  const state = createState();
  if (app !== undefined) {
    app.provide(UploadStoreKey, state);
  } else {
    provide(UploadStoreKey, state);
  }
  return createForState(state);
}

export function useStore (): IUploadStore {
  const state = inject<IUploadState>(UploadStoreKey);
  if (state === undefined) {
    throw new Error('Using UploadStore before providing it!');
  }
  return createForState(state);
}
