import { provide, inject, reactive, App, readonly } from 'vue';
import {
  ILeftDrawerState,
  ILeftDrawerStore,
  TSetLeftDrawerState,
  TToggleLeftDrawer
} from '@/store/contracts/leftDrawer';
import { impl } from '@/utils/impl';

const LeftDrawerStoreKey = Symbol('LeftDrawerStore');
let provided = false;

const createState = () => reactive(impl<ILeftDrawerState>({
  open: true
}));

function setLeftDrawerState (state: ILeftDrawerState): TSetLeftDrawerState {
  return (value) => {
    state.open = value;
  };
}

function toggleLeftDrawer (state: ILeftDrawerState): TToggleLeftDrawer {
  return () => {
    setLeftDrawerState(state)(!state.open);
  };
}

export function provideStore (app?: App<Element>): void {
  if (!provided) {
    provided = true;
    if (app !== undefined) {
      app.provide(LeftDrawerStoreKey, createState());
    } else {
      provide(LeftDrawerStoreKey, createState());
    }
  }
}

export function useStore (): ILeftDrawerStore {
  const state = inject<ILeftDrawerState>(LeftDrawerStoreKey);
  if (state === undefined) {
    throw new Error('Using LeftDrawerStore before providing it!');
  }

  return {
    state: readonly(state),
    setLeftDrawerState: setLeftDrawerState(state),
    toggleLeftDrawer: toggleLeftDrawer(state)
  };
}
