import Axios, { AxiosInstance } from 'axios';
import { Settings } from '@/settings';

export interface IAxiosClientSubscriptionKeyInterceptorOptions {
  axios?: AxiosInstance;
}

export function registerClientSubscriptionKeyInterceptor (options?: IAxiosClientSubscriptionKeyInterceptorOptions): void {
  const axios = options?.axios ?? Axios;

  axios.interceptors.request.use(
    (config) => {
      const urlStr = config.url ?? config.baseURL;
      if (urlStr) {
        const url = new URL(urlStr);
        const clientUrl = new URL(Settings.clientApiBaseUrl);
        if (url.hostname.toLowerCase() === clientUrl.hostname.toLowerCase()) {
          config.headers['Ocp-Apim-Subscription-Key'] = Settings.clientApiSubscriptionKey;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}
