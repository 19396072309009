/**
 * Use function snytax for creating an implementation of an interface
 * Without the need for an explicit cast, which would remove compile-time type safety.
 *
 * @param i the interface implementation
 * @return the interface implementation
 */
export function impl<I> (i: I): I {
  return i;
}
