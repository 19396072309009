import { Settings } from '@/settings';
import {
  ILoginState,
  ILoginStore,
  TGetAccessToken,
  TIsSignedIn,
  TLoadStoreState,
  TSignIn,
  TSignOut
} from '@/store/contracts/loginStore';
import { impl } from '@/utils/impl';
import { AccountInfo, AuthenticationResult, Configuration, EndSessionRequest, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { reactive, readonly } from '@vue/reactivity';
import { App, inject, provide } from '@vue/runtime-core';

const LoginStoreKey = Symbol('LoginStore');

const createState: () => ILoginState = () => {
  const msalConfig: Configuration = {
    auth: {
      clientId: Settings.msalClientId,
      authority: `https://login.microsoftonline.us/${Settings.msalTenantId}`
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true
    }
  };
  const msal = new PublicClientApplication(msalConfig);
  const loginRequest: RedirectRequest = { scopes: [`${Settings.msalAppIdentifier}/user_impersonation`] };
  return reactive<ILoginState>(impl<ILoginState>({
    msalConfig,
    msal,
    loginRequest,
    account: null
  })) as ILoginState;
};

function setAccount (state: ILoginState): (account: AccountInfo | null) => void {
  return (account) => {
    state.account = account;
  };
}

const getAccount: (msal: PublicClientApplication) => AccountInfo | null = (msal) => {
  const currentAccounts = msal.getAllAccounts();
  if (currentAccounts === null || currentAccounts.length === 0) {
    return null;
  }

  return currentAccounts[0];
};

function handleAuthResponse (state: ILoginState): (response: AuthenticationResult | null) => void {
  return (response) => {
    setAccount(state)(response?.account ?? getAccount(state.msal));
  };
}

function loadStoreState (state: ILoginState): TLoadStoreState {
  return async () => {
    const authResult = await state.msal.handleRedirectPromise();
    handleAuthResponse(state)(authResult);
    if (!isSignedIn(state)()) {
      await signIn(state)();
    }
  };
}

function signIn (state: ILoginState): TSignIn {
  return async () => {
    await state.msal.loginRedirect(state.loginRequest);
  };
}

function signOut (state: ILoginState): TSignOut {
  return async () => {
    const account = state.account ?? undefined;
    const logoutReq: EndSessionRequest = {
      account
    };
    await state.msal.logoutRedirect(logoutReq);
  };
}

function isSignedIn (state: ILoginState): TIsSignedIn {
  return () => {
    return state.account !== null;
  };
}

function getAccessToken (state: ILoginState): TGetAccessToken {
  return async () => {
    const account = state.account;
    if (account === null) {
      return null;
    }

    const response = await state.msal.acquireTokenSilent({
      ...state.loginRequest,
      account: account
    });

    return response.accessToken;
  };
}

const storeState = createState();

const createForState = (state: ILoginState) => impl<ILoginStore>({
  state: readonly(state),
  loadStoreState: loadStoreState(state),
  signIn: signIn(state),
  signOut: signOut(state),
  isSignedIn: isSignedIn(state),
  getAccessToken: getAccessToken(state)
});

/**
 * Store implementation that can be accessed outside of a component.
 */
export const store: ILoginStore = createForState(storeState);

export function provideStore (app?: App<Element>): ILoginStore {
  if (app !== undefined) {
    app.provide(LoginStoreKey, storeState);
  } else {
    provide(LoginStoreKey, storeState);
  }

  return store;
}

export function useStore (): ILoginStore {
  const state = inject<ILoginState>(LoginStoreKey);
  if (state === undefined) {
    return store;
  }
  return createForState(state);
}
