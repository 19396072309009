import { RouteLocationNormalized } from 'vue-router';
import { IIndexable } from '@/utils/indexable';

export type ParamTransform<T> = (paramValue: string | undefined | null) => T | undefined | null;

export type ParamTransformations = IIndexable<ParamTransform<any>>;

function doTransformations (
  transforms: ParamTransformations,
  query: boolean
): ((route: RouteLocationNormalized) => IIndexable<any>) {
  return (route: RouteLocationNormalized) => {
    const props: IIndexable<any> = { ...(query ? route.query : route.params) };
    Object.keys(transforms).forEach((param) => {
      const transform = transforms[param];
      props[param] = transform(props[param]);
    });
    return props;
  };
}

export function transformRouteParams (transforms: ParamTransformations): ((route: RouteLocationNormalized) => IIndexable<any>) {
  return doTransformations(transforms, false);
}

export function transformRouteQuery (transforms: ParamTransformations): ((route: RouteLocationNormalized) => IIndexable<any>) {
  return doTransformations(transforms, true);
}

/* Common parameter transformation functions */

export const toNumber: ParamTransform<number> = (paramValue: string | undefined | null) => {
  if (paramValue !== undefined && paramValue !== null) {
    const result = Number(paramValue);
    return isNaN(result) ? undefined : result;
  } else {
    return paramValue;
  }
};

export const passThrough: ParamTransform<string> = (paramValue: string | undefined | null) => {
  if (paramValue !== undefined && paramValue !== null) {
    return String(paramValue);
  } else {
    return paramValue;
  }
};

export const toBoolean: ParamTransform<boolean> = (paramValue: string | undefined | null) => {
  if (paramValue !== undefined && paramValue !== null) {
    return paramValue.trim().toLowerCase() === 'false' ? false : Boolean(paramValue);
  } else {
    return paramValue;
  }
};
