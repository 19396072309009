import { IIndexable } from '@/utils/indexable';

export interface IFieldValidationContext<T, V> {
  modelName: string;
  model: T | null;
  value: V;
}

export interface IValidationField<T, V> {
  getter: (model: T | null) => V;
  rules: Array<(validationContext: IFieldValidationContext<T, V>) => Promise<true | string>>;
}

export abstract class ValidationModel<T> {
  public fields: IIndexable<IValidationField<T, any>> = {};

  public abstract get modelName(): string;

  public setField<V> (key: string, field: IValidationField<T, V>): void {
    this.fields[key] = field;
  }

  public async validateField<V> (key: string, model: T | null): Promise<true | string> {
    const field = this.fields[key];
    if (field === undefined) {
      return true;
    }
    const value: V = field.getter(model);
    return (await Promise.all(
      field.rules
        .map((rule) => rule({
          modelName: this.modelName,
          model,
          value
        }))
    )).filter((result) => result !== true)[0] ?? true;
  }

  public async validate (model: T | null): Promise<IIndexable<true | string>> {
    const results: IIndexable<true | string> = {};
    await Promise.all(
      Object.keys(this.fields).map(async (key) => {
        results[key] = await this.validateField(key, model);
      })
    );
    return results;
  }

  public createForUUID (index: string): ValidationModel<T> {
    const thisModel = this;
    // disabling max class per file since this is anonymous.
    // tslint:disable-next-line: max-classes-per-file
    return new (class ValueValidationModel extends ValidationModel<T> {
      constructor () {
        super();
        this.fields = thisModel.fields;
      }

      public get modelName (): string {
        return `${thisModel.modelName}:[${index}]`;
      }
    })();
  }

  public createForIndex (index: number): ValidationModel<T> {
    const thisModel = this;
    // disabling max class per file since this is anonymous.
    // tslint:disable-next-line: max-classes-per-file
    return new (class ValueValidationModel extends ValidationModel<T> {
      constructor () {
        super();
        this.fields = thisModel.fields;
      }

      public get modelName (): string {
        return `${thisModel.modelName}:[${index}]`;
      }
    })();
  }

  public createForParent<P> (parentModel: ValidationModel<P>): ValidationModel<T> {
    const thisModel = this;
    // disabling max class per file since this is anonymous.
    // tslint:disable-next-line: max-classes-per-file
    return new (class ValueValidationModel extends ValidationModel<T> {
      constructor () {
        super();
        this.fields = thisModel.fields;
      }

      public get modelName (): string {
        return `${parentModel.modelName}:${thisModel.modelName}`;
      }
    })();
  }
}

export function createValidationModel (name: string): ValidationModel<any> {
  // disabling max class per file since this is anonymous.
  // tslint:disable-next-line:max-classes-per-file
  return new (class NamedValidationModel extends ValidationModel<any> {
    public get modelName (): string {
      return name;
    }
  })();
}
