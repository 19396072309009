import flatMap from 'array.prototype.flatmap';
import { impl } from './impl';

flatMap.shim();

Array.prototype.distinct = function () {
  return this.distinctBy((i) => i);
};

Array.prototype.distinctBy = function (getter: (value: any) => any) {
  const values: Set<any> = new Set();
  return this.filter((v) => {
    const value = getter(v);
    if (!values.has(value)) {
      values.add(value);
      return true;
    } else {
      return false;
    }
  });
};

Array.prototype.none = function (predicate: (item: any) => boolean): boolean {
  return !this.some(predicate);
};

Array.prototype.sortBy = function (
  getter: (item: any) => any,
  sortFn?: (value1: any, value2: any) => number
) {
  const result = [...this];
  result.sort((item1, item2) => {
    const value1 = getter(item1);
    const value2 = getter(item2);
    if (sortFn) {
      return sortFn(value1, value2);
    } else {
      // Here, we actually want just double-equals since we are sorting.
      // tslint:disable-next-line: triple-equals
      // eslint-disable-next-line eqeqeq
      return value1 == value2
        ? 0
        : value1 > value2 ? 1 : -1;
    }
  });
  return result;
};

Array.prototype.filterNulls = function () {
  return this.filter((v) => v !== undefined && v !== null);
};

Array.prototype.orDefault = function (arr: any[]) {
  if (this.length === 0) {
    return arr;
  } else {
    return this;
  }
};

Array.prototype.groupBy = function (getter: (item: any) => string | number) {
  return this.reduce((acc, next) => {
    const key = getter(next);
    acc[key] = [...(acc[key] ?? []), next];
    return acc;
  }, impl<IIndexable<any>>({}));
};

Array.prototype.min = function (defaultValue: number) {
  return this.reduce((acc, next) => {
    if (typeof next !== 'number') {
      throw new Error(`${next} is not a number`);
    }

    return next < acc ? next : acc;
  }, defaultValue);
};

Array.prototype.max = function (defaultValue: number) {
  return this.reduce((acc, next) => {
    if (typeof next !== 'number') {
      throw new Error(`${next} is not a number`);
    }

    return next > acc ? next : acc;
  }, defaultValue);
};
