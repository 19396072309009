import { createApp } from 'vue';
import '@/utils/arrayExtensions';
import '@/utils/stringExtensions';
import '@/utils/fileListExtensions';
import App from '@/App.vue';
import router from '@/router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from '@/quasar-user-options.js';

import { provideStore as provideLeftDrawerStore } from '@/store/leftDrawer';
import { provideStore as provideLoginStore } from '@/store/login';
import { provideStore as provideValidationStore } from '@/store/validation';
import { provideStore as provideUploadStore } from '@/store/apiTest/upload';
import { registerAdminMsalInterceptor } from '@/store/utils/axiosAdminMsalInterceptor';
import { registerAdminSubscriptionKeyInterceptor } from '@/store/utils/axiosAdminSubscriptionKeyInterceptor';
import { registerClientSubscriptionKeyInterceptor } from '@/store/utils/axiosClientSubscriptionKeyInterceptor';
import VueApexCharts from 'vue3-apexcharts';

const app = createApp(App);

app.use(Quasar, quasarUserOptions);
app.use(router);
app.use(VueApexCharts);

const loginStore = provideLoginStore(app);
registerAdminMsalInterceptor();
registerAdminSubscriptionKeyInterceptor();
registerClientSubscriptionKeyInterceptor();

loginStore.loadStoreState().then(() => {
  provideLeftDrawerStore(app);
  provideValidationStore(app);
  provideUploadStore(app);

  app.mount('#app');
});
